import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from '@reach/router';
import Image from 'gatsby-image';
import { FaPhoneAlt } from 'react-icons/fa';
import classNames from 'classnames';
import Select from 'react-select';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Link, changeLocale } from 'gatsby-plugin-intl';

// eslint-disable-next-line import/no-cycle
import { LanguageContext } from 'components/Layout';
import useNavbarData from 'queries/NavbarQueries';
import useScrollHandler from 'utilities/navbarScroll';
import styles from './navbar.module.scss';

const NavBar = () => {
  const isScrollOnTop = useScrollHandler();
  const [collapsedNavbar, setCollapsedNavbar] = useState(true);
  const {
    languages,
    navLogo,
    firstRowLinks,
    phoneNumber,
    mainNavLinks,
  } = useNavbarData();

  const location = useLocation();
  const { language, setLanguage } = useContext(LanguageContext);

  const [selectedLang, setSelectedLang] = useState('');

  useEffect(() => {
    const defaultSelected = languages?.find((lang) => lang?.abbreviation === language);

    setSelectedLang({ value: defaultSelected.abbreviation, label: defaultSelected.name });
  }, []);

  const toggleNavbar = () => {
    setCollapsedNavbar(!collapsedNavbar);
  };

  useEffect(() => {
    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', 'bs');
    }
  }, []);

  const options = [];

  languages.map((_language) => {
    options.push({ value: _language.abbreviation, label: _language.name });
    return null;
  });

  const handleChangeLanguage = (val) => {
    localStorage.setItem('lang', val.value);
    setSelectedLang(val);
    setLanguage(val.value);
    changeLocale(val.value);
  };

  const style = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: 'none',
      cursor: 'pointer',
      '&:hover': {
        opacity: 0.8,
      },
      background: 'transparent',
      color: 'white',
    }),
    singleValue: (base) => ({
      ...base,
      color: 'white',
    }),
    option: (base, state) => ({
      ...base,
      cursor: 'pointer',
      '&:hover': {
        opacity: 0.6,
      },
      display: state.isSelected ? 'none' : 'block',
    }),
    menu: (base) => ({
      ...base,
      borderRadius: '10px',
      background: 'white',
    }),
    menuList: (base) => ({
      ...base,
      borderRadius: '10px',
    }),
    placeholder: (base) => ({
      ...base,
      color: 'white',
    }),
    valueContainer: (base) => ({
      ...base,
      width: '70px',
      marginTop: '-15px',
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),
    indicatorsContainer: (base) => ({
      ...base,
      marginTop: '-15px',
    }),
  };

  let animationDelayTime = 0.1;

  return (
    <nav
      className={classNames(
        styles.navbar,
        isScrollOnTop ? styles.showNav : styles.hideNav,
      )}
    >
      <div className={styles.navbarContainer}>
        <Link to="/">
          <Image fluid={navLogo} className={styles.companyLogo} />
        </Link>
        <div className={styles.linksContainer}>
          <div className={styles.upperNavbar}>
            <span className={styles.phoneNumber}>
              <FaPhoneAlt className={styles.icon} />
              {phoneNumber}
            </span>
            {firstRowLinks.map((firstRowLink) => {
              if (firstRowLink.url.split('/')[0] !== '') {
                return (
                  <a href={firstRowLink.url} key={firstRowLink.id}>
                    {firstRowLink[`title_${language}`]}
                  </a>
                );
              }
              return (
                <Link
                  activeClassName="active"
                  activeStyle={{
                    color: 'white',
                    fontWeight: 700,
                  }}
                  key={firstRowLink.id}
                  to={firstRowLink.url}
                >
                  {firstRowLink[`title_${language}`]}
                </Link>
              );
            })}
            <Select
              options={options}
              className={styles.selectLangugage}
              styles={style}
              value={selectedLang}
              onChange={handleChangeLanguage}
              isSearchable={false}
              isClearable={false}
            />
          </div>
          <button
            className={classNames(
              styles.navbarToggleButton,
              collapsedNavbar ? '' : styles.navbarOpen,
            )}
            type="button"
            onClick={toggleNavbar}
            aria-label="Toggle navigation"
          >
            <div className={classNames(styles.line, styles.lineTop)} />
            <div className={classNames(styles.line, styles.lineMiddle)} />
            <div className={classNames(styles.line, styles.lineBottom)} />
          </button>
          <div
            className={classNames(
              'collapse navbar-collapse',
              styles.bottomNavbar,
              styles.navbarCollapse,
              collapsedNavbar ? '' : styles.show,
            )}
          >
            {mainNavLinks.map((mainNavLink) => {
              animationDelayTime += 0.1;
              if (mainNavLink.is_anchor === true && location.pathname === '/') {
                return (
                  <AnchorLink
                    style={{
                      animationDelay: `${animationDelayTime.toString()}s`,
                    }}
                    key={mainNavLink.strapiId}
                    href={mainNavLink.url}
                    onClick={toggleNavbar}
                  >
                    {mainNavLink[`text_${language}`]}
                  </AnchorLink>
                );
              }
              if (mainNavLink.is_anchor === true && location.pathname !== '/') {
                return (
                  <Link
                    activeClassName="active"
                    activeStyle={{
                      color: 'white',
                      fontWeight: 700,
                    }}
                    style={{
                      animationDelay: `${animationDelayTime.toString()}s`,
                    }}
                    key={mainNavLink.strapiId}
                    to={`/${mainNavLink.url}`}
                  >
                    {mainNavLink[`text_${language}`]}
                  </Link>
                );
              }
              return (
                <Link
                  activeClassName="active"
                  activeStyle={{
                    color: 'white',
                    fontWeight: 700,
                  }}
                  style={{
                    animationDelay: `${animationDelayTime.toString()}s`,
                  }}
                  key={mainNavLink.strapiId}
                  to={mainNavLink.url}
                >
                  {mainNavLink[`text_${language}`]}
                </Link>
              );
            })}
            {firstRowLinks.map((firstRowLink) => {
              animationDelayTime += 0.1;
              if (firstRowLink.url.split('/')[0] !== '') {
                return (
                  <a
                    href={firstRowLink.url}
                    key={firstRowLink.id}
                    style={{
                      animationDelay: `${animationDelayTime.toString()}s`,
                    }}
                    className={styles.mobileLink}
                  >
                    {firstRowLink[`title_${language}`]}
                  </a>
                );
              }
              return (
                <Link
                  activeClassName="active"
                  activeStyle={{
                    color: 'white',
                    fontWeight: 700,
                  }}
                  style={{
                    animationDelay: `${animationDelayTime.toString()}s`,
                  }}
                  key={firstRowLink.id}
                  className={styles.mobileLink}
                  to={firstRowLink.url}
                >
                  {firstRowLink[`title_${language}`]}
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
