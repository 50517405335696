import { graphql, useStaticQuery } from 'gatsby';

const useFooterData = () => {
  const { allStrapiFooter: { nodes: data } } = useStaticQuery(graphql`
    {
      allStrapiFooter {
        nodes {
          contact {
            title_bs
            address_bs
            title_en
            address_en
            title_de
            address_de
            email
            fax
            phone
          }
          top_links {
            title_bs
            title_en
            title_de
            url
            id
          }
          logo {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          bottom_links {
            id
            title_bs
            title_en
            title_de
            url
          }
          socials {
            id
            network
            url
          }
          copyright
        }
      }
    }
  `);
  const footerData = data[0];
  return {
    contact: footerData.contact,
    top_links: footerData.top_links,
    logo: footerData.logo,
    bottom_links: footerData.bottom_links,
    socials: footerData.socials,
    copyright: footerData.copyright,
  };
};

export default useFooterData;
