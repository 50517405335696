import { graphql, useStaticQuery } from 'gatsby';

const useNavbarData = () => {
  const { allStrapiNavlinks, allStrapiNavItems, allStrapiLanguage } = useStaticQuery(graphql`
    query {
        allStrapiNavlinks(sort: {fields: strapiId}) {
          nodes {
            strapiId
            text_bs
            text_en
            text_de
            url
            is_anchor
          }
        }
        allStrapiNavItems {
          nodes {
            logo {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            links {
              id
              title_bs
              title_en
              title_de
              url
            }
            number
          }
        }
        allStrapiLanguage {
          nodes {
            language {
              name
              abbreviation
            }
          }
        }
      }
`);

  return {
    languages: allStrapiLanguage.nodes[0].language.map((node) => node),
    navLogo: allStrapiNavItems.nodes[0].logo.childImageSharp.fluid,
    firstRowLinks: allStrapiNavItems.nodes[0].links,
    phoneNumber: allStrapiNavItems.nodes[0].number,
    mainNavLinks: allStrapiNavlinks.nodes.map((node) => node),
  };
};

export default useNavbarData;
