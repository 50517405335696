import React, { useState } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';

import Footer from 'components/Footer/Footer';
import NavBar from 'components/NavBar/NavBar';

import SEO from './seo';

import './layout.scss';

const globalWindow = typeof window !== 'undefined' && window;

export const LanguageContext = React.createContext({
  language: globalWindow?.localStorage?.getItem('lang') || 'bs',
  setLanguage: () => {},
});

const Layout = ({
  children, title, description, image,
}) => {
  const intl = useIntl();
  const [language, setLanguage] = useState(intl.locale);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      <SEO title={title} description={description} image={image} />
      <NavBar />
      {children}
      <Footer />
    </LanguageContext.Provider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default Layout;
