import React, { useContext } from 'react';
import { useLocation } from '@reach/router';
import Image from 'gatsby-image';
import {
  TiSocialFacebook, TiSocialLinkedin, TiSocialTwitter, TiSocialPinterest,
} from 'react-icons/ti';
import { Link } from 'gatsby-plugin-intl';
import standard9001 from 'images/standard9001.png';
import standard14001 from 'images/standard14001.png';
import ihaaLogoII from 'images/type-ii.png';
import ihaaLogoIII from 'images/type-iii.png';

import useFooterData from '../../queries/FooterQueries';
import { LanguageContext } from '../Layout';
import styles from './footer.module.scss';

const Footer = () => {
  const content = useFooterData();
  const { language } = useContext(LanguageContext);
  const location = useLocation();

  return (
    <div className={styles.footerContainer}>
      <div className={styles.upperFooterContainer}>
        <div className={styles.contactContainer}>
          <span className={styles.copyright}>{content.copyright}</span>
          <h2>{content.title}</h2>
          <span className={styles.address}>{content.contact[`address_${language}`]}</span>
          <span>
            Tel:
            {content.contact.phone}
          </span>
          <span>
            Fax:
            {content.contact.fax}
          </span>
          <span>
            E-mail:
            {content.contact.email}
          </span>
          <div className={styles.logoContainer}>
            <img className={styles.standardLogo} src={standard9001} alt="standard 9001" />
            <img className={styles.standardLogo} src={standard14001} alt="standard 14001" />
            <a href="https://www.ihanodizing.com/the-ihaa-quality-seal/" target="_blank" rel="noreferrer">
              <img className={styles.ihaaLogo} src={ihaaLogoII} alt="Ihaa logo II" />
            </a>
            <a href="https://www.ihanodizing.com/the-ihaa-quality-seal/" target="_blank" rel="noreferrer">
              <img className={styles.ihaaLogo} src={ihaaLogoIII} alt="Ihaa logo III" />
            </a>
          </div>
        </div>
        <div className={styles.links}>
          {
            content.top_links.map((link, index) => {
              if (index < content.top_links.length / 2) {
                if (location.pathname !== '/' && link.url.charAt(0) === '#') {
                  return (
                    <Link key={link.id} to={`/${link.url}`}>{link[`title_${language}`]}</Link>
                  );
                }
                return (
                  <Link key={link.id} to={link.url}>{link[`title_${language}`]}</Link>
                );
              }
              return null;
            })
          }
        </div>
        <div className={styles.links}>
          {
            content.top_links.map((link, index) => {
              if (index > (content.top_links.length / 2 - 1)) {
                if (link.url.split('/')[0] !== '') {
                  return (
                    <a key={link.id} href={link.url}>{link[`title_${language}`]}</a>
                  );
                }
                return (
                  <Link key={link.id} to={link.url}>{link[`title_${language}`]}</Link>
                );
              }
              return null;
            })
          }
        </div>
        <button type="button" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} className={styles.scrollToTop}>
          <Image fluid={content.logo.childImageSharp.fluid} className={styles.logoImage} />
        </button>
      </div>
      <hr />
      <div className={styles.bottomFooterContainer}>
        <div className={styles.bottomLinks}>
          {
              content.bottom_links.map((link) => (
                <div key={link.id} className={styles.bottomLink}>
                  <Link to={link.url}>{link[`title_${language}`]}</Link>
                </div>
              ))
          }
        </div>
        <div className={styles.socialNetworks}>
          {
            content.socials.map((social) => {
              switch (social.network) {
                case 'facebook':
                  return (
                    <a key={social.id} href={social.url} target="_blank" rel="noreferrer">
                      <TiSocialFacebook className={styles.socialIcon} />
                    </a>
                  );
                case 'linkedin':
                  return (
                    <a key={social.id} href={social.url} target="_blank" rel="noreferrer">
                      <TiSocialLinkedin className={styles.socialIcon} />
                    </a>
                  );
                case 'twitter':
                  return (
                    <a key={social.id} href={social.url} target="_blank" rel="noreferrer">
                      <TiSocialTwitter className={styles.socialIcon} />
                    </a>
                  );
                case 'pinterest':
                  return (
                    <a key={social.id} href={social.url} target="_blank" rel="noreferrer">
                      <TiSocialPinterest className={styles.socialIcon} />
                    </a>
                  );
                default:
                  return null;
              }
            })
          }
        </div>
      </div>
      <span className={styles.copyrightMobile}>{content.copyright}</span>
      {language === 'bs' ? (
        <div className={styles.disclaimer}>
          Ova internetska stranica je kreirana i održava se uz finansijsku podršku Evropske unije (EU) i Federalnog ministarstva za ekonomsku saradnju i razvoj Vlade SR Njemačke (BMZ) u okviru EU4Business projekta u BiH. Sadržaj ove internetske stranice je isključiva odgovornost GAT-a i nužno ne odražava stanovišta EU ili BMZ.
        </div>
      ) : (
        <div className={styles.disclaimer}>
          This website was created and maintained with the financial support of the European Union and the German Federal Ministry for Economic Cooperation and Development (BMZ). Its contents are the sole responsibility of GAT, and do not necessarily reflect the views of the EU or the BMZ.
        </div>
      )}
    </div>
  );
};

export default Footer;
