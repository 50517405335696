import { useState, useEffect } from 'react';

const useScrollHandler = (scrollHeight = 30) => {
  const [scroll, setScroll] = useState(1);

  useEffect(() => {
    const onScroll = () => {
      const scrollCheck = window.scrollY < scrollHeight;

      if (scrollCheck !== scroll) {
        setScroll(scrollCheck);
      }
    };

    document.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, [scroll, setScroll, scrollHeight]);

  return scroll;
};

export default useScrollHandler;
